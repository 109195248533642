import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const SocialLinks = () => {
    const data = useStaticQuery(graphql`
    query SocialQuery {
      site {
        siteMetadata {
          social {
            twitter
            codepen
            linkedin
            github
            instagram
          }
        }
      }
    }
  `)
    const { social } = data.site.siteMetadata;

    return (
        <span className="social-links">
            <span><a className="icon-link" href={`https://instagram.com/${social.instagram}`} target="_blank" rel="noreferrer">
                <i className="fab fa-instagram"></i>
            </a></span>
            <span><a className="icon-link" href={`https://twitter.com/${social.twitter}`} target="_blank" rel="noreferrer">
                <i className="fab fa-twitter"></i>
            </a></span>
            <span><a className="icon-link" href={`https://linkedin.com/in/${social.linkedin}`} target="_blank" rel="noreferrer">
              <i className="fab fa-linkedin-in"></i>
            </a></span>
            <span><a className="icon-link" href={`https://codepen.io/${social.codepen}`} target="_blank" rel="noreferrer">
                <i className="fab fa-codepen"></i>
            </a></span>
        </span>
    )

}

export default SocialLinks;